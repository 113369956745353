<template>
  <v-container fluid v-if="azione && bancale">
    <v-layout>
      <v-flex>
        <page-title :text="pageTitle" style="text-transform: capitalize;"></page-title>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex>
        <info v-if="azione==='info'" />
        <posizioneQrCode ref="posizione" evento="inserita-posizione" v-if="azione==='alloca'" />
        <uscitaBancaleQrCode v-if="azione==='uscita'" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import PageTitle from '@/components/common/PageTitle'
import Info from '@/components/magazzino/gestione/Info'
import PosizioneQrCode from '@/components/magazzino/gestione/PosizioneQrCode'
import UscitaBancaleQrCode from '@/components/magazzino/gestione/UscitaBancaleQrCode'
export default {
  data: () => ({
    azione: null,
    bancale: null
  }),
  components: {
    PageTitle,
    Info,
    PosizioneQrCode,
    UscitaBancaleQrCode
  },
  computed: {
    pageTitle () {
      return `${this.azione} bancale ${this.bancale}`
    }
  },
  mounted () {
    this.azione = this.$route.query.azione
    this.bancale = this.$route.query.bancale
  }
}
</script>
