
<template>
    <v-layout>
      <v-flex class="mt-4">
        <v-chip
          color="green"
          text-color="white"
          v-if="infoBancale && !infoBancale.padre_id && infoBancale.qta_totale > infoBancale.qta_prelevata && infoBancale.posizione && infoBancale.posizione !== 'TMP'">
          <span v-if="infoBancale.sede===1">Cormano-</span>
          <span v-else>Paderno-</span>
          {{infoBancale.posizione}}
        </v-chip>
        <v-chip
          color="grey"
          text-color="white"
          v-if="infoBancale && !infoBancale.padre_id && infoBancale.qta_totale > infoBancale.qta_prelevata && infoBancale.posizione && infoBancale.posizione === 'TMP'">
          <span v-if="infoBancale.sede===1">Cormano-</span>
          <span v-else>Paderno-</span>
          TMP
        </v-chip>
        <v-chip
            color="yellow"
            text-color="black"
            v-if="infoBancale && !infoBancale.padre_id && infoBancale.qta_totale > infoBancale.qta_prelevata && !infoBancale.posizione">
            <span v-if="infoBancale.sede===1">Cormano-</span>
            <span v-else>Paderno-</span>
            +
        </v-chip>
        <v-chip
            color="red"
            text-color="white"
            v-if="(infoBancale && (infoBancale.padre_id || infoBancale.qta_totale === infoBancale.qta_prelevata))">
            <span v-if="infoBancale.sede===1">Cormano-</span>
            <span v-else>Paderno-</span>
            <span v-if="infoBancale.ddt_id">Uscito</span>
            <span v-else>In uscita</span>
        </v-chip>

        <span v-if="infoBancale && infoBancale.progressivo">{{infoBancale.num_bancale}}</span>
        <span v-else>{{infoBancale._id}} - {{infoBancale.ragione_sociale}}</span>
        Qta
        <span v-if="infoBancale.padre_id">{{infoBancale.qta_prelevata}}</span>
        <span v-else>{{infoBancale.qta_totale}} - (rimanenza {{infoBancale.qta_totale - infoBancale.qta_prelevata}})</span>
        <span v-if="infoBancale.prelievi"> - </span>
        <span class="underline--text"
            v-if="infoBancale.prelievi === 1 && !infoBancale.padre_id"
        >
          1 prelievo
        </span>
          <span class="secondary--text underline--text"
            v-if="infoBancale.prelievi > 1 && !infoBancale.padre_id">
            {{infoBancale.prelievi}} prelievi
        </span>

        <v-list class="pa-4 mt-4" v-if="infoBancale">
          <h3 class="mb-4">Componenti:</h3>
          <template v-for="(item, index) in infoBancale.more_data">
            <v-list-tile-content :key="'more-'+index">
              <v-list-tile-title class="my-1">
              {{item.descrizione}}
              </v-list-tile-title>
            </v-list-tile-content>
          </template>
        </v-list>

      </v-flex>
    </v-layout>
</template>

<script>
export default {
  data: () => ({
    bancale: null,
    infoBancale: {}
  }),
  methods: {
    processData () {
      this.infoBancale.descrizione = null
      this.infoBancale.more_data = []
      this.infoBancale.componenti.forEach((item) => {
        let desc = ''
        if (item.opera) desc += item.opera
        if (item.componente) desc += ' - ' + item.componente
        if (item.descrizione && item.descrizione !== '-') desc += ' - ' + item.descrizione
        if (item.codice) desc += ' - ' + item.codice
        if (item.componente) desc += ' - ' + item.componente
        if (item.note) desc += ' - ' + item.note
        desc += ' Qta ' + item.qta
        // if (this.infoBancale.descrizione === null) {
        //  this.infoBancale.descrizione = desc
        // } else {
        this.infoBancale.more_data.push({ descrizione: desc })
        // }
      })
    },

    getInfoBancale () {
      this.$plsqlRead('gestione', this.bancale)
        .then((response) => {
          this.infoBancale = response.data
          this.processData()
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  mounted () {
    this.bancale = this.$route.query.bancale
    this.getInfoBancale()
  }
}
</script>
