<template>
  <span>
    <v-card
      class="pa-4 mt-4"
    >
      <v-flex>
        <v-layout>
          <v-flex xs12>
            <v-text-field
              class="ma-12"
              label="Posizione"
              :disabled="intmp"
              :rules="[$rules.maxlength(6)]"
              v-model="bancale.posizione" />
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-checkbox v-model="intmp" @change="bancale.posizione = intmp ? 'TMP' : null" label="TMP" />
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12 class="pa-2">
            <v-btn
              class="ml-0"
              color="primary"
              :disabled="(!bancale.posizione) || (bancale.posizione && bancale.posizione.length>6)"
              @click.native="setPosizione()">
              Salva
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-card>
  </span>
</template>

<script>
import { $EventBus } from '@/eventBus'

const comp = {
  data: () => ({
    bancale: {},
    codiceBancale: null,
    intmp: false
  }),
  props: {
    evento: ''
  },
  methods: {
    setPosizione () {
      this.$plsqlMethod('gestione', 'set_posizione', { bancale_id: this.bancale._id, posizione: this.bancale.posizione })
        .then(response => {
          this.showPosizione = false
          $EventBus.$emit('message', { type: 'success', text: 'Operazione efettuata cn successo' })
          this.$router.push({ name: 'gestione' })
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    },
    init (b) {
      this.intmp = this.bancale.posizione === 'TMP'
    },
    getInfoBancale () {
      this.$plsqlRead('gestione', this.codiceBancale)
        .then((response) => {
          this.bancale = response.data
        })
        .catch((err) => {
          console.log(err)
          // $EventBus.$emit('message', { type: 'error', text: 'Impossibile recuperare il bancale' })
        })
    }
  },
  mounted () {
    this.codiceBancale = this.$route.query.bancale
    this.getInfoBancale()
  }
}

export default comp
</script>
