<template>
  <div>
    <v-layout row wrap class="mb-2">
      <v-flex xs12 md6 lg3>
        <v-radio-group v-model="modalita" :mandatory="false"  >
          <v-radio label="Prelievo parziale" value="0" @click.native="checkBottoneSalva()"></v-radio>
          <v-radio label="Prelievo totale" value="1" @click.native="checkBottoneSalva()"></v-radio>
        </v-radio-group>
      </v-flex>
      <v-flex xs12 md6 lg9>
        <v-select
          label="Tipo *"
          :items="tipiPrelievo"
          item-text="text"
          item-value="val"
          v-model="prelv.tipo"
         v-on:change="checkBottoneSalva()"/>
      </v-flex>
    </v-layout>

    <v-layout row wrap>
      <v-flex xl12 v-if="bancaliModale.length">
        <v-card
          v-for="bancale in bancaliModale"
          v-model="bancale.active"
          :key="'tile-'+bancale._id">
          <v-card-title>
            <div v-on:click.stop class="chipPosition">
              <v-chip
                color="green"
                text-color="white"
                v-if="!bancale.padre_id && bancale.posizione && bancale.posizione !== 'TMP'">
                <span v-if="bancale.sede===1">C-</span>
                <span v-else>P-</span>
                {{bancale.posizione}}
              </v-chip>
              <v-chip
                color="grey"
                text-color="black"
                v-if="!bancale.padre_id && bancale.posizione && bancale.posizione === 'TMP'">
                <span v-if="bancale.sede===1">C-</span>
                <span v-else>P-</span>
                TMP
              </v-chip>
              <v-chip
                color="yellow"
                text-color="black"
                v-if="!bancale.padre_id && !bancale.posizione">
                <span v-if="bancale.sede===1">C-</span>
                <span v-else>P-</span>
                +
              </v-chip>
              <v-chip
                color="red"
                text-color="white"
                v-if="bancale.padre_id">
                <span v-if="bancale.sede===1">C-</span>
                <span v-else>P-</span>
                In uscita
              </v-chip>
            </div>
            <span v-if="bancale.progressivo">{{bancale.num_bancale}}</span>
            <span v-else>{{bancale._id}}</span>
            <span v-if="bancale.componenti.length === 1">1 componente - </span>
            <span v-if="bancale.componenti.length != 1">{{bancale.componenti.length}} componenti - </span>
            <span>&nbsp;Qta&nbsp;</span>
            <span v-if="bancale.padre_id"> {{bancale.qta_prelevata}}</span>
            <span v-else>{{bancale.qta_totale}}</span>
            <span v-if="bancale.prelievi">&nbsp;-&nbsp;</span>
            <span v-if="bancale.prelievi === 1">1 prelievo</span>
            <span v-if="bancale.prelievi > 1">{{bancale.prelievi}} prelievi</span>
          </v-card-title>

          <v-card-text>
            <v-container fluid grid-list-xl>
              <v-layout py-2 row wrap v-for="comp in bancale.componenti" :key="comp.componente_id" class="border-bottom">
                <v-flex sm12 md6 lg2>
                  <v-text-field
                    label="Opera"
                    v-model="comp.opera"
                    disabled />
                </v-flex>
                <v-flex sm12 md6 lg2>
                  <v-text-field
                    label="Descrizione"
                    v-model="comp.descrizione"
                    disabled />
                </v-flex>
                <v-flex sm12 md6 lg1>
                  <v-text-field
                    label="Codice"
                    v-model="comp.codice"
                    disabled />
                </v-flex>
                <v-flex sm12 md6 lg2>
                  <v-text-field
                    label="Componente"
                    v-model="comp.componente"
                    disabled />
                </v-flex>
                <v-flex sm12 md12 lg2>
                  <v-text-field
                    label="Note"
                    v-model="comp.note"
                    disabled />
                </v-flex>
                <v-flex xs7 sm6 md6 lg1>
                  <v-text-field
                    v-if="modalita === '0' && comp.qta - comp.qta_prelevata"
                    label="Quantità *"
                    :name="'field-'+comp._id"
                    v-model="comp.qta_da_prelevare"
                    :rules="[$rules.numeric, $rules.ngt(comp.qta - comp.qta_prelevata)]"
                    v-on:change="checkBottoneSalva()" />
                  <span class="subheading lh4" v-if="modalita === '1'">{{comp.qta - comp.qta_prelevata}}</span>
                </v-flex>
                <v-flex xs5 sm6 md6 lg2 mt-4>
                  Su totale di {{comp.qta - comp.qta_prelevata}}
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout class="mb-2 mt-3">
      <v-flex xl12>
        <v-btn
          class="ml-0"
          color="primary"
          :disabled="!abilitaPrelievo"
          @click.native="savePrelievo()">
          Prelievo
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { $EventBus } from '@/eventBus'

const comp = {
  data: () => ({
    bancale: null,
    codiceBancale: null,
    bancaliModale: [],
    tipiPrelievo: [
      { val: 0, text: 'Pedido' },
      { val: 1, text: 'Traslado' },
      { val: 2, text: 'Muestra' },
      { val: 3, text: 'Prelievo' }
    ],
    prelv: {
      data: new Date(Date.now())
        .toLocaleString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })
        .slice(0, 10)
    },
    modalita: '0',
    qtInserita: false,
    abilitaPrelievo: false
  }),
  props: {

  },
  methods: {
    getInfoBancale () {
      this.$plsqlRead('gestione', this.codiceBancale)
        .then((response) => {
          this.bancale = response.data
          this.bancaliModale.push(this.bancale)
          this.setTipoPrelievo()
        })
        .catch((err) => {
          console.log(err)
          // $EventBus.$emit('message', { type: 'error', text: 'Impossibile recuperare il bancale' })
        })
    },
    savePrelievo: function () {
      this.setTipoPrelievo()
      this.prelv.bancali = this.bancaliModale
      this.$plsqlMethod('gestione', 'prelievo', this.prelv)
        .then(response => {
          $EventBus.$emit('message', { type: 'success', text: 'Prelievo andato a buon fine.' })
          this.$router.push({ name: 'gestione' })
        })
        .catch(err => {
          console.error('errore savePrelievo', err)
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    },
    setTipoPrelievo: function () {
      for (let bancale of this.bancaliModale) {
        if (this.modalita === '1') {
          for (let c of bancale.componenti) {
            c.qta_da_prelevare = c.qta - c.qta_prelevata
          }
        }
      }
    },
    checkBottoneSalva: function () {
      if (this.prelv.tipo == null) {
        this.abilitaPrelievo = false
        return
      }

      if (this.modalita === '1') {
        this.abilitaPrelievo = true
        return
      }

      this.abilitaPrelievo = false
      for (let bancale of this.bancaliModale) {
        for (var i = 0; i < bancale.componenti.length; i++) {
          var objComp = bancale.componenti[i]
          if (objComp.qta_da_prelevare && objComp.qta_da_prelevare > 0) {
            this.abilitaPrelievo = true
            return
          }
          i++
        }
      }
    }
  },
  mounted () {
    this.codiceBancale = this.$route.query.bancale
    this.getInfoBancale()
  }
}

export default comp
</script>

<style scoped>
  .v-list__group {
    border-bottom: 1px solid rgba(0,0,0,0.12);
  }
  .rowWrapper {
    height: auto;
    line-height: inherit;
    padding: 10px 0;
  }
  .rowWrapper-row {
    height: 90px;
    margin-top: 0!important;
  }
  .v-form > .container {
    padding: 24px;
  }
  .chipPosition {
    min-width: 85px;
    text-align: center;
  }
  .border-bottom {
    border-bottom:1px solid #999;
  }
  .lh4 {
    line-height: 4.5;
  }
</style>
